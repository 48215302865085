import React from "react";
import TrackingTag from "components/TrackingTag/TrackingTag";
import styles from "./PageElements.module.scss";
import { motion } from "framer-motion";
import tafel from "../assets/images/tafel-2.jpg";

const trackTagProps = {
	config: {
		pageName: "speculasies",
		subsite: false,
		chapters: [],
		siteId: 80,
		initialisationCode: 630429,
		initialisationCodeForTest: 630430,
		siteType: "standalone",
		platform: "site",
		broadcaster: "ntr",
		idfa: false,
		program: "sinterklaasjournaal",
		eventType: "home",
	},
	customVars: {
		1: "[site]",
		2: "[ntr]",
		3: "[sinterklaasjournaal]",
		4: "[standalone]",
		6: "[speculasies]",
		7: "[general]",
	},
	settings: {
		name: "speculasies-home",
		level2: 80,
	},
};

const Home = () => {
	return (
		<>
			<motion.div
				className={styles.textblock}
				initial="hidden"
				animate="visible"
				exit={{ opacity: 0, y: "-30vh", transition: { duration: 0.4 } }}
				variants={{ visible: { transition: { staggerChildren: 0.3 } } }}>
				<h2>Een trommel vol Speculasies!</h2>
				<p>
					Speculasies is het programma waarin we vooruitkijken en terugblikken
					op de uitzending van het Sinterklaasjournaal. Aan tafel worden de
					belangrijkste gebeurtenissen en de grootste mysteries rondom Sinterklaas besproken met deskundigen. 
					Sidekick Stephanie praat ons bij over de inzendingen uit het land, en er is muziek van de Suprise Knip en Plakband!
				</p>
				<p>
					Speculasies was iedere zaterdag te zien, meteen na Het Sinterklaasjournaal. Tot volgende seizoen! 
				</p>
				<h3>In aflevering 4:</h3>
				<p>De Stoomboot ligt wéér stil, de fietsen staan wéér op slot en Sinterklaas is wéér verdwenen. Houdt het dan nooit op?</p>				
				<p>
					<a
						aria-label="Naar aflevering 4 op NPO Start"
						target="_blank"
						rel="noopener noreferrer"
						href="https://npostart.nl/VPWON_1361683">
						Bekijk aflevering 4 op NPO Start
					</a>
				</p>
				<img
					src={tafel}
					className={styles.presentator}
					alt="Presentator en gasten aflevering 3"
				/>
				<h3>In aflevering 3:</h3>
				<p>De cadeautjes zijn op, Sinterklaas ligt in het ziekenhuis en Yvonne Kletsmeijer heeft schokkend nieuws over Ozosnel.</p>				
				<p>
					<a
						aria-label="Naar aflevering 3 op NPO Start"
						target="_blank"
						rel="noopener noreferrer"
						href="https://npo.nl/start/serie/speculasies/seizoen-2/speculasies_5/afspelen">
						Bekijk aflevering 3 op NPO Start
					</a>
				</p>
				<h3>In aflevering 2:</h3>
				<p>
				Nederland krijgt alles waar het om vraagt. Nieuws van Yvonne Kletsmeijer en de TV-Dokter maakt Sinterklaas beter.</p>
				<p>
					<a
						aria-label="Naar aflevering 1 op NPO Start"
						target="_blank"
						rel="noopener noreferrer"
						href="https://npo.nl/start/serie/speculasies/seizoen-2/speculasies_8/afspelen">
						Bekijk aflevering 2 op NPO Start
					</a>
				</p>
					<h3>In aflevering 1:</h3>
				<p>
				Wie wordt de nieuwe Hoofdpiet, Jaap van Stamelen onthult het geheim van Sinterklaas en hoe gezond is Het Sinterklaasfeest? Dat laatste bespreken we met een echte TV-dokter.
				</p>
				<p>
					<a
						aria-label="Naar aflevering 1 op NPO Start"
						target="_blank"
						rel="noopener noreferrer"
						href="https://npo.nl/start/serie/speculasies/seizoen-2/speculasies_6/afspelen">
						Bekijk aflevering 1 op NPO Start
					</a>
				</p>

			</motion.div>
			<TrackingTag tag={trackTagProps} />
		</>
	);
};

export default Home;
